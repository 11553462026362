import { Vue, Options } from 'vue-class-component';
import { IonHeader, IonToolbar, IonButtons, IonTextarea, modalController } from '@ionic/vue';
import IconTimes from '@/views/components/icons/IconTimes.vue';
import contentsClient from '@/core/api/contents-service';
import { Patient, PatientMessageCreate } from '@/../client-generator/generated-clients/api-clients';
import IconSearch from '@/views/components/icons/IconSearch.vue';
import IconTrashRed from '@/views/components/icons/IconTrashRed.vue';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import PreviewImageModalDedication from './PreviewImageModalDedication/PreviewImageModalDedication.vue';
import uploadClient from '@/core/api/upload-service';
const { takePhoto } = usePhotoGallery();
import { message } from '@/core/utilities/utilities';
import { DECREMENT_PATIENT_REMAINING_MESSAGES, SET_BYPASS_DISCLAIMER } from '@/core/store/types/action-types';
import { mapState } from 'vuex';

@Options({
    name: 'TimelineModalNewDedication',
    props: {
        patient: {
            type: String,
            required: true,
        },
    },
    components: {
        IconTimes,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonTextarea,
        IconSearch,
        IconTrashRed,
        PatientMessageCreate,
        PreviewImageModalDedication,
    },
    computed: {
        ...mapState(['selectedPatient']),
    },
})
export default class TimelineModalNewDedication extends Vue {
    message = message;
    getOrTakePhoto = takePhoto;
    showAddDedication = false;
    dedicationText = '';
    dedicationImage: { base64: string; fileName: string; webPath: string } | null = null;
    selectedPatient!: Patient;

    $refs!: {
        chosenImageInput: HTMLFormElement;
    };

    sendDedication() {
        const data = new PatientMessageCreate();
        data.message = this.dedicationText;
        this.send(data);
    }

    get remainingChars() {
        return 2500 - this.dedicationText.length;
    }

    async send(data: PatientMessageCreate) {
        try {
            if (this.dedicationImage) {
                const response = await uploadClient.uploadPostCustom(this.dedicationImage.base64, this.dedicationImage.fileName);
                data.attachment = response.id?.toString();
            }
            if (!this.selectedPatient.id) return;
            await contentsClient.patientMessage(data, this.selectedPatient.id.toString());
            this.$store.dispatch(DECREMENT_PATIENT_REMAINING_MESSAGES);
        } catch (error) {
            this.message(this.$t('apiError'));
        } finally {
            await this.handleCloseModal();
        }
    }

    async handleCloseModal() {
        await (window as any).currentModal.dismiss();
        delete (window as any).currentModal;
    }

    async deletePhoto() {
        this.dedicationImage = null;
    }

    async takePhoto() {
        this.$store.dispatch(SET_BYPASS_DISCLAIMER, true);
        this.$refs.chosenImageInput.click();
    }

    previewFiles(e: any) {
        this.$store.dispatch(SET_BYPASS_DISCLAIMER, true);
        const file = e.target.files[0] as File;
        this.$refs.chosenImageInput.value = null;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader && reader.result)
                this.dedicationImage = { base64: reader.result.toString(), fileName: file.name.split('.')[0] + '.jpg', webPath: '' };
            this.$store.dispatch(SET_BYPASS_DISCLAIMER, false);
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
            this.$store.dispatch(SET_BYPASS_DISCLAIMER, false);
        };
    }

    async toggleShowImagePreview(doShow: boolean) {
        if (doShow) {
            const modal = await modalController.create({
                component: PreviewImageModalDedication,
                componentProps: {
                    webPath: this.dedicationImage?.base64,
                },
            });
            (window as any).currentModalImage = modal;
            modal.present();
        } else {
            if ((window as any).currentModalImage) await (window as any).currentModalImage.dismiss();
        }
    }
}
