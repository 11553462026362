<template>
    <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <path
            fill="currentColor"
            d="M384.002,447.99976H127.99414a31.99963,31.99963,0,0,0-32.001,32.00012V512H416.00293V479.99988A31.99963,31.99963,0,0,0,384.002,447.99976ZM509.791,268.419l-29.78607-12.42-12.4203-29.78527a3.99315,3.99315,0,0,0-7.15451,0L448.00977,255.999l-29.78607,12.42a3.99924,3.99924,0,0,0,0,7.15433l29.78607,12.42,12.4203,29.78527a3.99471,3.99471,0,0,0,7.15451,0l12.4203-29.78527,29.78607-12.42a4.00082,4.00082,0,0,0,0-7.15433ZM63.99023,32.00208,51.56993,2.21876a3.99314,3.99314,0,0,0-7.15451,0L31.99512,32.00208,2.20905,44.422a4.0024,4.0024,0,0,0,0,7.15628l29.78607,12.42,12.4203,29.78332a3.99314,3.99314,0,0,0,7.15451,0l12.4203-29.78332,29.78607-12.42a4.00082,4.00082,0,0,0,0-7.15628Z"
        ></path>
    </svg>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    name: "IconZupit",
})
export default class IconZupit extends Vue {}
</script>
