<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="primary">
                    <ion-button
                        class="button-close"
                        color="danger"
                        @click="cancel"
                    >
                        <IconTimes class="text-black" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div
                class="d-flex align-items-stretch text-black ask-question-title"
            >
                <h4>
                    {{ $t("questionForDoctor") }}
                </h4>
            </div>
            <ion-card class="ask-question-card">
                <ion-card-header>
                    <div class="alert alert-secondary" role="alert">
                        <i18n keypath="askQuestion.alert" tag="p">
                            <span class="fw-bold">{{
                                $t("askQuestion.alertContentBold")
                            }}</span>
                        </i18n>
                    </div>
                </ion-card-header>
                <ion-card-content>
                    <div class="text-black">
                        <div class="fw-bold">
                            {{ $t("questionForDoctor") }}
                        </div>
                        <div class="max-chars">
                            {{ $t("maxChars", { chars: 350 }) }}
                        </div>
                        <ion-textarea
                            class="message-textarea"
                            v-model="questionText"
                            type="text"
                            rows="5"
                            minlength="1"
                            maxlength="350"
                        ></ion-textarea>
                    </div>
                    <div class="remaining-chars">
                        {{ $t("remainingChars") }}:
                        <span>
                            {{ remainingChars }}
                        </span>
                    </div>
                    <div
                        class="alert alert-danger"
                        role="alert"
                        v-if="questionError"
                    >
                        {{ $t("askQuestion.maxReached") }}
                    </div>
                    <div class="text-right mt-4">
                        <ion-button
                            fill="clear"
                            class="text-black"
                            @click="cancel"
                            >{{ $t("cancel") }}</ion-button
                        >
                        <ion-button
                            color="primary"
                            @click="submit"
                            :disabled="!questionText"
                            >{{ $t("send") }}</ion-button
                        >
                    </div>
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./AskQuestionModal.ts"></script>

<style lang="scss" src="./AskQuestionModal.scss"></style>
