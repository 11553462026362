import { ref } from 'vue';
import { Plugins, CameraResultType, CameraSource, CameraPhoto } from '@capacitor/core';

const photos = ref<Photo[]>([]);

export function usePhotoGallery() {
    const { Camera } = Plugins;
    const convertBlobToBase64 = (blob: Blob) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });

    const savePicture = async (photo: CameraPhoto): Promise<string> => {
        // Fetch the photo, read as a blob, then convert to base64 format
        // eslint-disable-next-line
        const response = await fetch(photo.webPath!);
        const blob = await response.blob();
        const base64Data = (await convertBlobToBase64(blob)) as string;

        // const savedFile = await Filesystem.writeFile({
        //   path: fileName,
        //   data: base64Data,
        //   directory: FilesystemDirectory.Data
        // });

        // Use webPath to display the new image instead of base64 since it's
        // already loaded into memory
        return base64Data;
    };
    const takePhoto = async () => {
        const cameraPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt,
            quality: 100,
        });
        if (!cameraPhoto.webPath) return null;

        const fileName = new Date().getTime() + '.jpeg';
        const imgDataUri = await savePicture(cameraPhoto);
        // eslint-disable-next-line
        return { base64: imgDataUri, fileName: fileName, webPath: cameraPhoto.webPath! };
    };

    return {
        photos,
        takePhoto,
    };
}

export interface Photo {
    filepath: string;
    webviewPath?: string;
}
