<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" style="">
        <rect
            id="backgroundrect"
            width="100%"
            height="100%"
            x="0"
            y="0"
            fill="none"
            stroke="none"
        />

        <g class="currentLayer" style="">
            <title>Layer 1</title>
            <g fill="#dc3545" id="svg_1" class="selected" fill-opacity="1">
                <path
                    d="M0.7740000000000001,10.827 c0,0.8520000000000002 0.6930000000000004,1.546 1.546,1.546 h6.187 c0.8530000000000002,0 1.5470000000000002,-0.6940000000000004 1.5470000000000002,-1.546 V3.093 H0.7740000000000001 v7.734 zM6.96,0.773 L6.96,0 L3.867,0 L3.867,0.773 L-3.469446951953614e-18,0.773 L-3.469446951953614e-18,2.3200000000000003 L10.827,2.3200000000000003 L10.827,0.773 z"
                    id="svg_2"
                    fill="#dc3545"
                    fill-opacity="1"
                />
            </g>
        </g>
    </svg>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    name: "IconTrashRed",
})
export default class IconTrashRed extends Vue {}
</script>
