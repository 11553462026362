<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-card class="jitsi-call-card">
                <ion-card-content class="jitsi-content">
                    <div v-if="isLoading" class="jitsi-loader">
                        <ion-spinner />
                        <h2 class="mt-4">{{ $t("callLoading") }}</h2>
                    </div>
                    <template v-else>
                        <div v-if="jitsiError" class="alert alert-danger">
                            {{ $t("jitsiErrors") }}
                            <div class="mt-4 mb-1 d-flex flex-row-reverse">
                                <ion-button
                                    class="text-black"
                                    fill="clear"
                                    @click="handleCloseModal()"
                                >
                                    {{ $t("back") }}
                                </ion-button>
                            </div>
                        </div>
                        <!-- <div class="jitsi"></div> -->
                    </template>
                    <div class="jitsi"></div>
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./TimelineJitsiCall.ts"></script>

<style lang="scss" src="./TimelineJitsiCall.scss"></style>
