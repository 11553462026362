<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
    >
        <g fill="none" fill-rule="evenodd">
            <circle
                cx="11"
                cy="11"
                r="10"
                fill="currentColor"
                stroke="#FFF"
                stroke-width="2"
            />
            <g fill="#FFF" fill-rule="nonzero" transform="translate(7 6)">
                <circle cx="3.475" cy="10.068" r="1.337" />
                <path
                    d="M3.475 0C1.559 0 0 1.559 0 3.475v.334h2.673v-.334c0-.442.36-.802.802-.802.442 0 .802.36.802.802 0 .236-.104.459-.284.612L2.138 5.664v2.4h2.673V6.9l.913-.776c.78-.662 1.226-1.628 1.226-2.65C6.95 1.56 5.39 0 3.475 0z"
                />
            </g>
        </g>
    </svg>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    name: "IconQuestionCircle",
})
export default class IconQuestionCircle extends Vue {}
</script>
