import { Vue, Options } from 'vue-class-component';
import { IonHeader, IonToolbar, IonButtons } from '@ionic/vue';
import IconTimes from '@/views/components/icons/IconTimes.vue';

@Options({
    name: 'PreviewImageModalDedication',
    props: {
        webPath: {
            type: String,
            required: true,
        },
    },
    components: {
        IonHeader,
        IonToolbar,
        IonButtons,
        IconTimes,
    },
})
export default class PreviewImageModalDedication extends Vue {
    async handleClose() {
        await (window as any).currentModalImage.dismiss();
        delete (window as any).currentModalImage;
    }
}
