<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="26"
        viewBox="0 0 16 26"
    >
        <g fill="currentColor">
            <circle cx="7.8" cy="22.6" r="3" />
            <path
                d="M7.8 0C3.499 0 0 3.499 0 7.8v.75h6V7.8C6 6.807 6.807 6 7.8 6s1.8.807 1.8 1.8c0 .529-.232 1.03-.637 1.374L4.8 12.713V18.1h6v-2.613l2.049-1.742C14.597 12.26 15.6 10.093 15.6 7.8c0-4.301-3.5-7.8-7.8-7.8z"
            />
        </g>
    </svg>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    name: "IconQuestion",
})
export default class IconQuestion extends Vue {}
</script>
