import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';

import jitsiClient from '@/core/api/jitsi-service';
import meClient from '@/core/api/me-service';
import contentsClient from '@/core/api/contents-service';
import { Content, IRegisterDevice, Patient, RegisterDevice } from '@/../client-generator/generated-clients/api-clients';

import { dateFormattingOptions } from '@/core/utilities/datetime';
import { message } from '@/core/utilities/utilities';
import { READ_TIMELINE_CONTENTS, SET_PROFILE, SET_TIMELINE_CONTENTS } from '@/core/store/types/action-types';

import IconPlus from '@/views/components/icons/IconPlus.vue';
import IconQuestionCircle from '@/views/components/icons/IconQuestionCircle.vue';
import IconHeart from '@/views/components/icons/IconHeart.vue';
import IconEmpty from '@/views/components/icons/IconEmpty.vue';
import TimelineCard from './TimelineCard/TimelineCard.vue';
import TimelineModalNewDedication from './TimelineModalNewDedication/TimelineModalNewDedication.vue';
import AskQuestionModal from './AskQuestionModal/AskQuestionModal.vue';
import TimelineJitsiCall from './TimelineJitsiCall/TimelineJitsiCall.vue';

import { IonButtons, IonSpinner, modalController, alertController, IonIcon } from '@ionic/vue';
import { chevronBackOutline as backIcon, swapHorizontalOutline as swapIcon } from 'ionicons/icons';
import firebase from 'firebase';
import notificationService from '@/core/api/notification-service';

@Options({
    name: 'Timeline',
    components: {
        TimelineCard,
        IonButtons,
        IonSpinner,
        IonIcon,
        TimelineModalNewDedication,
        AskQuestionModal,
        IconPlus,
        IconQuestionCircle,
        IconHeart,
        IconEmpty,
        TimelineJitsiCall,
    },
    computed: {
        ...mapState({ selectedPatient: 'selectedPatient', cards: 'timelineContents' }),
    },
})
export default class Timeline extends Vue {
    isLoading = true;

    backIcon = backIcon;
    swapIcon = swapIcon;

    cards!: Content[];

    showAddDedication = false;
    showAskQuestion = false;

    message = message;
    selectedPatient!: Patient;

    timeNowInterval?: any; // interval to clear on destroy
    timeNowTimeout: any = null;

    updateDataInterval?: any; // interval to clear on destroy

    jitsiInterval: any = null;

    get groupedCards() {
        if (this.cards.length === 0) return {};

        const groupedCards: { [key: string]: Content[] } = {};

        this.cards.forEach((card) => {
            let cardDate: string | Date = card.answer ? card.answer.created : card.created;

            cardDate = cardDate.toLocaleDateString('it-IT', dateFormattingOptions);

            if (!groupedCards[cardDate]) {
                groupedCards[cardDate] = [];
            }

            groupedCards[cardDate].push(card);
        });

        return groupedCards;
    }

    get hasCards() {
        return Object.keys(this.groupedCards).length > 0;
    }

    get canAskQuestions() {
        return this.selectedPatient?.remainingQuestions > 0;
    }

    get canSubmitMessage() {
        return this.selectedPatient?.remainingPatientMessages > 0;
    }

    get patientFirstName() {
        return this.selectedPatient?.firstName;
    }

    get patientLastName() {
        return this.selectedPatient?.lastName;
    }

    async ionViewWillEnter() {
        this.askNotificationPermission();
        await this.$store.dispatch(READ_TIMELINE_CONTENTS);
        await this.fetchData();
        this.updateTimeNowStart();
        await this.updateDataTimeoutStart();
        this.hasJitsiCall();
        this.timeNowInterval = setInterval(this.updateTimeNowStart, 60 * 1000); //every 1 minute
        this.updateDataInterval = setInterval(this.updateDataTimeoutStart, 30 * 1000); //every 30 seconds
        this.jitsiInterval = setInterval(() => this.hasJitsiCall(), 3 * 1000); // every 3 seconds
    }

    async ionViewWillLeave() {
        if (this.timeNowTimeout) clearInterval(this.timeNowTimeout);
        if (this.updateDataInterval) clearInterval(this.updateDataInterval);
        await this.toggleShowAddDedication(false);
        await this.toggleShowAddQuestion(false);
        if (this.jitsiInterval) {
            clearInterval(this.jitsiInterval);
        }
    }

    async fetchData() {
        if (!this.cards.length) {
            this.isLoading = true;
        }

        try {
            if (!this.selectedPatient.id) return;
            const response = await contentsClient.contents(this.selectedPatient.id.toString());
            if (JSON.stringify(this.cards) !== JSON.stringify(response)) {
                this.$store.dispatch(SET_TIMELINE_CONTENTS, response);
            }
        } catch (error) {
            this.message(this.$t('apiError'));
        } finally {
            this.isLoading = false;
        }
    }

    async fetchUserProfile() {
        const profile = await meClient.me();
        this.$store.dispatch(SET_PROFILE, profile);
    }

    updateTimeNowStart() {
        if (!this.showAddDedication && !this.showAskQuestion) {
            const nowDate = new Date();
            this.$store.dispatch(
                SET_TIMELINE_CONTENTS,
                this.cards.filter((x) => !x.expiration || new Date(x.expiration) > nowDate)
            );
            this.timeNowTimeout = setTimeout(this.updateTimeNowStart, 60 * 1000); //every 1 minute
        }
    }

    async updateDataTimeoutStart() {
        if (!this.showAddDedication && !this.showAskQuestion) {
            await this.fetchData();
            await this.fetchUserProfile();
        }
    }

    async toggleShowAddDedication(doShow: boolean) {
        if (doShow) {
            const modal = await modalController.create({
                component: TimelineModalNewDedication,
                componentProps: {
                    patient: this.selectedPatient.firstName,
                },
            });
            (window as any).currentModal = modal;
            modal.onDidDismiss().then(() => this.fetchData());
            modal.present();
        } else {
            if ((window as any).currentModalImage) {
                await (window as any).currentModalImage.dismiss();
                delete (window as any).currentModalImage;
            }
            if ((window as any).currentModal) {
                await (window as any).currentModal.dismiss();
                delete (window as any).currentModal;
            }
        }
    }

    async hasJitsiCall() {
        if ((window as any).jitsiModal !== undefined) {
            return;
        }

        try {
            //da eseguire una in fila a l'altra, le altre due devono essere eseguite solo se la prima va a buon fine.
            if (!this.selectedPatient.id) return;
            await jitsiClient.hasJitsiCall(this.selectedPatient.id.toString());

            await Promise.all([this.toggleShowAddQuestion(false), this.toggleShowAddDedication(false)]);
            this.toggleShowCall(true);
        } catch (error) {
            this.toggleShowCall(false);
            // la chiusura della chiamata a popup aperto la gestisco dentro al componente
            console.log(error);
        }
    }

    async toggleShowAddQuestion(doShow: boolean) {
        if (doShow) {
            const modal = await modalController.create({
                component: AskQuestionModal,
            });
            (window as any).currentModal = modal;
            modal.onDidDismiss().then(() => this.fetchData());
            modal.present();
        } else {
            if ((window as any).currentModal) {
                await (window as any).currentModal.dismiss();
                delete (window as any).currentModal;
            }
        }
    }

    async toggleShowCall(doShow: boolean) {
        if (doShow && !(window as any).jitsiModal) {
            const modal = await modalController.create({
                component: TimelineJitsiCall,
            });
            (window as any).jitsiModal = modal;
            modal.onDidDismiss().then(() => this.fetchData());
            modal.present();
        } else if (!doShow && (window as any).jitsiModal && (window as any).jitsi) {
            (window as any).jitsi.executeCommand('hangup');
            await (window as any).jitsiModal.dismiss();
            delete (window as any).jitsiModal;
            delete (window as any).jitsi;
        }
    }

    goToQuestion() {
        if (this.selectedPatient.remainingQuestions > 0) this.toggleShowAddQuestion(true);
    }

    goToDedication() {
        if (this.selectedPatient.remainingPatientMessages > 0) this.toggleShowAddDedication(true);
    }

    goTo(route: string) {
        this.$router.push({
            name: route,
        });
    }

    async askNotificationPermission() {
        if (!('Notification' in window)) {
            const alert = await alertController.create({
                message: this.$t('notifications.notsupported'),
                buttons: [
                    {
                        text: this.$t('notifications.askPermission.ok'),
                    },
                ],
            });
            alert.present();
        }
        if (Notification.permission === 'default') {
            const alert = await alertController.create({
                message: this.$t('notifications.askPermission.description'),
                buttons: [
                    {
                        text: this.$t('notifications.askPermission.ok'),
                        handler: () => {
                            Notification.requestPermission().then((permission) => {
                                if (permission === 'granted') {
                                    this.registerNotificationPermission(true);
                                }
                            });
                        },
                    },
                ],
            });
            alert.present();
        }
        if (Notification.permission === 'granted') {
            this.registerNotificationPermission();
        }
    }

    registerNotificationPermission(newDevice = false) {
        const messaging = firebase.messaging();
        messaging
            .getToken({
                vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
            })
            .then((resp) => {
                if (newDevice) {
                    const data: IRegisterDevice = { deviceRegistrationId: resp };
                    notificationService.registerDevice(new RegisterDevice(data));
                }
            });

        messaging.onMessage(() => {
            this.hasJitsiCall();
        });
    }
}
