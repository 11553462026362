<template>
    <div>
        <ion-spinner v-if="isLoading" />
        <template v-else>
            <p v-if="mediaLoadFailed">Media load failed</p>
            <template v-else>
                <div v-if="isImage">
                    <ion-img
                        :src="url"
                        @click="toggleShowMedia(true)"
                        alt="media"
                    />
                    <ion-modal
                        :is-open="showMediaModal"
                        @onDidDismiss="toggleShowMedia(false)"
                    >
                        <TimelineMediaModal
                            :media-type="mediaType"
                            :media-url="url"
                            @closeMedia="toggleShowMedia(false)"
                        />
                    </ion-modal>
                </div>
                <video
                    v-else-if="isVideo"
                    :src="url"
                    type="video/mp4"
                    preload="none"
                    controls
                    controlsList="nodownload"
                ></video>
                <audio
                    v-else-if="isAudio"
                    :src="url"
                    preload="none"
                    controls
                    controlsList="nodownload"
                ></audio>
            </template>
        </template>
    </div>
</template>

<script lang="ts" src="./TimelineCardMedia.ts"></script>
