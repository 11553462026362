<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="primary">
                    <ion-button
                        class="button-close"
                        color="danger"
                        @click="handleCloseModal()"
                    >
                        <IconTimes class="text-black" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div
                class="d-flex align-items-stretch text-black dedication-card-title"
            >
                <h4>
                    {{ $t("sendDedicationToPatient", { patient: patient }) }}
                </h4>
            </div>

            <ion-card class="dedication-card">
                <ion-card-header>
                    <div class="alert alert-secondary text-black" role="alert">
                        {{ $t("sendDedicationToPatient2Start") }}
                        <strong>{{ patient }}</strong>
                        {{ $t("sendDedicationToPatient2End") }}
                    </div>
                </ion-card-header>
                <ion-card-content>
                    <div class="text-black">
                        <strong>{{
                            $t("sendDedicationToPatient3", {
                                patient: patient,
                            })
                        }}</strong>
                        <div class="max-chars">
                            {{ $t("sendDedicationToPatientMaxChars") }}
                        </div>
                        <ion-textarea
                            v-model="dedicationText"
                            type="text"
                            class="message-textarea"
                            rows="5"
                            minlength="1"
                            maxlength="2500"
                            placeholder="Inserisci la dedica qui.."
                        ></ion-textarea>
                    </div>
                    <div class="remaining-chars">
                        {{ $t("remainingChars") }}:
                        <span>
                            {{ remainingChars }}
                        </span>
                    </div>
                    <div class="mt-3 text-black d-flex align-items-center">
                        <strong>{{ $t("sendPhotoText") }}</strong>
                        <input
                            v-show="false"
                            type="file"
                            @change="previewFiles"
                            accept="image/*"
                            ref="chosenImageInput"
                        />
                        <ion-button
                            v-if="!dedicationImage"
                            class="text-black ml-3"
                            fill="outline"
                            @click="takePhoto"
                            >{{ $t("sendPhotoBtn") }}
                        </ion-button>
                    </div>
                    <div
                        class="alert alert-secondary mt-4"
                        v-if="dedicationImage"
                    >
                        <div>{{ dedicationImage.fileName }}</div>
                        <div>
                            <ion-button
                                class="text-black"
                                fill="clear"
                                @click="toggleShowImagePreview(true)"
                                ><IconSearch class="mr-1" />{{
                                    $t("viewPhoto")
                                }}
                            </ion-button>
                            <ion-button
                                class="text-black"
                                fill="clear"
                                @click="deletePhoto()"
                                ><IconTrashRed class="mr-1" />{{
                                    $t("deletePhoto")
                                }}
                            </ion-button>
                        </div>
                    </div>
                    <div class="mt-4 mb-1 d-flex flex-row-reverse">
                        <ion-button
                            :disabled="!dedicationText"
                            @click="sendDedication()"
                            >{{ $t("send") }}</ion-button
                        >
                        <ion-button
                            class="text-black"
                            fill="clear"
                            @click="handleCloseModal()"
                            >{{ $t("cancel") }}</ion-button
                        >
                    </div>
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./TimelineModalNewDedication.ts"></script>

<style lang="scss" src="./TimelineModalNewDedication.scss"></style>
