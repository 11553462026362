import { Vue, Options } from 'vue-class-component';
import { dateFormattingOptions, timeFormattingOptins } from '@/core/utilities/datetime';

import { IonImg, IonModal, IonActionSheet } from '@ionic/vue';
import { close as IonicIconClose, trash as IonicIconTrash } from 'ionicons/icons';

import IconComment from '@/views/components/icons/IconComment.vue';
import IconEnvelope from '@/views/components/icons/IconEnvelope.vue';
import IconQuestion from '@/views/components/icons/IconQuestion.vue';
import IconTrash from '@/views/components/icons/IconTrash.vue';

import TimelineCardMedia from './TimelineCardMedia/TimelineCardMedia.vue';
import TimelineCardRepliesTo from './TimelineCardRepliesTo/TimelineCardRepliesTo.vue';
import { Content, ContentType } from '@/../client-generator/generated-clients/api-clients';
import contentsClient from '@/core/api/contents-service';

@Options({
    name: 'TimelineCard',
    props: {
        card: {
            type: Object as () => Content,
            required: true,
        },
    },
    components: {
        IonImg,
        IonModal,
        IonActionSheet,
        TimelineCardMedia,
        TimelineCardRepliesTo,
        IconComment,
        IconEnvelope,
        IconQuestion,
        IconTrash,
    },
    emits: ['card-hidden'],
})
export default class TimelineCard extends Vue {
    card!: Content;
    iconBackgroundClass = 'bg-blue';

    isConfirmCardDeleteVisible = false;

    get hasMedia() {
        if (this.card.answer) {
            return !!this.card.answer.attachment;
        }

        return !!this.card.attachment;
    }

    get iconComponent() {
        let iconComponent = '';

        switch (this.card.type) {
            case ContentType.Operator_message: {
                iconComponent = 'IconComment';
                this.iconBackgroundClass = 'bg-blue';
                break;
            }

            case ContentType.Patient_message: {
                iconComponent = 'IconEnvelope';
                this.iconBackgroundClass = 'bg-purple';
                break;
            }

            case ContentType.Relative_question: {
                if (this.card.answer) {
                    iconComponent = 'IconComment';
                    this.iconBackgroundClass = 'bg-blue';
                } else {
                    iconComponent = 'IconQuestion';
                    this.iconBackgroundClass = 'bg-yellow';
                }
                break;
            }

            default:
                iconComponent = 'IconComment';
        }

        return iconComponent;
    }

    get attachmentURL() {
        const attachmentUrl = this.card.answer ? this.card.answer.attachment : this.card.attachment;
        return attachmentUrl;
    }

    get attachmentTokenURL() {
        return this.card.attachmentTokenUrl;
    }

    get cardText() {
        if (this.card.answer) {
            return this.card.answer.message;
        }

        return this.card.message;
    }

    get formattedDate() {
        const dateUsed = this.card.answer ? this.card.answer.created : this.card.created;
        return dateUsed.toLocaleDateString('it-IT', dateFormattingOptions);
    }

    get formattedTime() {
        const dateUsed = this.card.answer ? this.card.answer.created : this.card.created;
        return dateUsed.toLocaleTimeString(undefined, timeFormattingOptins);
    }

    get formattedExpiration() {
        const dateUsed = this.card.answer ? this.card.answer.expiration : this.card.expiration;
        if (dateUsed) {
            const datePart = dateUsed.toLocaleDateString('it-IT', dateFormattingOptions);
            const timePart = dateUsed.toLocaleTimeString(undefined, timeFormattingOptins);
            return `${datePart} - ${timePart}`;
        }

        return null;
    }

    get cardDeleteButtons() {
        return [
            {
                text: this.$t('confirm'),
                role: 'destructive',
                icon: IonicIconTrash,
                handler: this.handleCardDelete,
            },
            {
                text: this.$t('cancel'),
                icon: IonicIconClose,
                role: 'cancel',
            },
        ];
    }

    get showRemoveButton() {
        return this.card.canBeHidden;
    }

    toggleConfirmCardDelete(isOpen: boolean) {
        this.isConfirmCardDeleteVisible = isOpen;
    }

    async handleCardDelete() {
        try {
            await contentsClient.hideOperatorMessage((this.card.id as number).toString());
            this.$emit('card-hidden');
        } catch (error) {
            console.log(error.toString());
        }
    }
}
