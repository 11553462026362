<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="13"
        viewBox="0 0 11 13"
    >
        <g fill="currentcolor">
            <path
                d="M.779 10.827c0 .852.693 1.546 1.546 1.546h6.187c.853 0 1.547-.694 1.547-1.546V3.093H.779v7.734zM6.965.773L6.965 0 3.872 0 3.872.773.005.773.005 2.32 10.832 2.32 10.832.773z"
            />
        </g>
    </svg>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    name: "IconTrash",
})
export default class IconTrash extends Vue {}
</script>
