<template>
    <div class="timeline-card-wrapper">
        <ion-card class="timeline-card">
            <ion-card-header>
                <strong class="timeline-card__date text-black mb-1">
                    {{ formattedDate }}
                </strong>
                <span class="timeline-card__time">{{ formattedTime }}</span>

                <div class="timeline-card__header-right">
                    <div
                        class="timeline-card__icon"
                        :class="iconBackgroundClass"
                    >
                        <component :is="iconComponent" />
                    </div>
                </div>
            </ion-card-header>
            <ion-card-content>
                <TimelineCardRepliesTo v-if="card.answer" :repliesTo="card" />
                <div
                    class="timeline-card__content text-black"
                    v-html="cardText"
                ></div>
                <TimelineCardMedia
                    v-if="hasMedia"
                    :media-type="card.attachmentMimeType"
                    :media-url="attachmentURL"
                    :media-token-url="attachmentTokenURL"
                    class="timeline-card__media"
                />
                <button
                    v-if="showRemoveButton"
                    class="timeline-card__action-button"
                    @click="toggleConfirmCardDelete(true)"
                >
                    <IconTrash />
                    <span class="ml-2">{{ $t("remove") }}</span>
                </button>
            </ion-card-content>
        </ion-card>
        <ion-action-sheet
            v-if="showRemoveButton"
            :is-open="isConfirmCardDeleteVisible"
            :header="$t('confirmCardDeleteTitle')"
            :sub-header="$t('confirmCardDeleteSubtitle')"
            :buttons="cardDeleteButtons"
            @onDidDismiss="toggleConfirmCardDelete(false)"
            css-class="timeline-card__actions"
        >
        </ion-action-sheet>
    </div>
</template>

<script lang="ts" src="./TimelineCard.ts"></script>

<style lang="scss" src="./TimelineCard.scss"></style>
