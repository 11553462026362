import { Vue, Options } from 'vue-class-component';
import { IonHeader, IonSpinner } from '@ionic/vue';
import jitsiClient from '@/core/api/jitsi-service';
import { JitsiRoomData, Patient } from '@/../client-generator/generated-clients/api-clients';
import { mapState } from 'vuex';

declare let JitsiMeetExternalAPI: any;

@Options({
    name: 'TimelineJitsiCall',
    components: {
        IonHeader,
        IonSpinner,
    },
    computed: {
        ...mapState(['selectedPatient']),
    },
})
export default class TimelineJitsiCall extends Vue {
    isLoading = true;
    dedicationText = '';
    jitsiError = false;
    selectedPatient!: Patient;

    async mounted() {
        this.createCall();
    }

    async createCall() {
        try {
            if ((window as any).jitsi) {
                return;
            }
            let roomData: any = null;
            await setTimeout(async () => {
                roomData = await this.getRoomData();

                if (roomData != null) {
                    this.jitsiError = false;
                    const jwt = roomData.jwt;
                    const roomName = roomData.room;
                    const domain = roomData.server;
                    // const jwt = '';
                    // const roomName = 'ProvaZupit';
                    // const domain = 'meet.jit.si';

                    (window as any).jitsi = new JitsiMeetExternalAPI(domain, {
                        roomName: roomName,
                        width: '100%',
                        height: '100%',
                        parentNode: document.querySelector('.jitsi'),
                        jwt: jwt,
                        noSsl: false,
                        configOverwrite: {
                            disableDeepLinking: true,
                        },
                        interfaceConfigOverwrite: {
                            TOOLBAR_BUTTONS: ['microphone', 'camera', 'fullscreen', 'fodeviceselection', 'hangup'],
                        },
                        onload: async () => {
                            if ((window as any).jitsi) {
                                if ((window as any).jitsi.getNumberOfParticipants <= 0) {
                                    await this.closeCall();
                                }
                                (window as any).jitsi.on('participantLeft', async () => {
                                    await this.closeCall();
                                });
                            }
                            this.isLoading = false;
                        },
                    });
                } else {
                    //error getting jitsi server info
                    throw new Error('error getting jitsi server info');
                }
            }, 5000);
        } catch (error) {
            console.log(JSON.stringify(error));
            this.jitsiError = true;
            this.isLoading = false;
        }
    }

    async getRoomData(): Promise<JitsiRoomData | null> {
        let roomData = null;
        try {
            if (!this.selectedPatient.id) return null;
            roomData = await jitsiClient.roomData(this.selectedPatient.id.toString());
            return roomData;
        } catch (error) {
            console.log(JSON.stringify(error));
        }
        return null;
    }

    async closeCall() {
        //console.log('NUMBER_OF_PARTECIPANTS:' + (window as any).jitsi.getNumberOfParticipants());
        if ((window as any).jitsi && (window as any).jitsi.getNumberOfParticipants() <= 1) {
            (window as any).jitsi.executeCommand('hangup');
            await this.handleCloseModal();
        } else {
            console.error('Cannot hangup, jitsi not started');
        }
    }

    async handleCloseModal() {
        console.log('Jitsi modal dismiss');
        await (window as any).jitsiModal.dismiss();
        delete (window as any).jitsiModal;
        delete (window as any).jitsi;
    }
}
