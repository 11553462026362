<template>
    <svg viewBox="0 0 512 405" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m451.32 115.43c3.4887-2.773 8.677-0.17891 8.677 4.2043v182.93c0 23.705-19.232 42.938-42.938 42.938h-372.12c-23.705 0-42.938-19.232-42.938-42.938v-182.84c0-4.4727 5.0988-6.9773 8.677-4.2043 20.038 15.565 46.605 35.334 137.85 101.62 18.875 13.776 50.72 42.759 82.476 42.58 31.935 0.26836 64.406-29.341 82.565-42.58 91.242-66.285 117.72-86.143 137.76-101.71zm-220.32 115.57c20.753 0.35781 50.63-26.12 65.659-37.034 118.7-86.143 127.74-93.657 155.11-115.13 5.1883-4.0254 8.2297-10.287 8.2297-16.907v-16.996c0-23.705-19.232-42.938-42.938-42.938h-372.12c-23.705 0-42.938 19.232-42.938 42.938v16.996c0 6.6195 3.0414 12.792 8.2297 16.907 27.373 21.379 36.407 28.983 155.11 115.13 15.028 10.913 44.905 37.391 65.659 37.034z"
            fill="currentColor"
            stroke="null"
        />
        <g stroke="null">
            <path
                d="m491.41 271.38c-16.578-14.127-41.233-11.586-56.449 4.1142l-5.9595 6.141-5.9595-6.141c-15.186-15.7-39.871-18.242-56.449-4.1142-18.998 16.215-19.996 45.317-2.9949 62.893l58.536 60.442c3.7814 3.9024 9.9224 3.9024 13.704 0l58.536-60.442c17.032-17.576 16.033-46.678-2.9646-62.893z"
                fill="currentColor"
                stroke="#a473da"
                stroke-width="20"
            />
        </g>
    </svg>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    name: "IconEnvelope",
})
export default class IconEnvelope extends Vue {}
</script>
