import { Vue, Options } from 'vue-class-component';

import { dateFormattingOptions, timeFormattingOptins } from '@/core/utilities/datetime';
import { Content } from '@/../client-generator/generated-clients/api-clients';

@Options({
    name: 'TimelineCardRepliesTo',
    props: {
        repliesTo: {
            type: Object as () => Content,
            required: true,
        },
    },
})
export default class TimelineCardRepliesTo extends Vue {
    repliesTo!: Content;

    get formattedDate() {
        return this.repliesTo.created.toLocaleDateString('it-IT', dateFormattingOptions);
    }

    get formattedTime() {
        return this.repliesTo.created.toLocaleTimeString(undefined, timeFormattingOptins);
    }
}
