<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
    >
        <path
            fill="currentColor"
            d="M24.615 0C11.042 0 0 11.042 0 24.615 0 38.19 11.042 49.231 24.615 49.231c13.574 0 24.616-11.042 24.616-24.616C49.23 11.042 38.189 0 24.615 0zm10.77 26.667h-8.718v8.718c0 1.132-.92 2.05-2.052 2.05-1.132 0-2.05-.918-2.05-2.05v-8.718h-8.719c-1.132 0-2.051-.92-2.051-2.052 0-1.132.919-2.05 2.051-2.05h8.718v-8.719c0-1.132.919-2.051 2.051-2.051 1.133 0 2.052.919 2.052 2.051v8.718h8.718c1.132 0 2.05.919 2.05 2.051 0 1.133-.918 2.052-2.05 2.052z"
        />
    </svg>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    name: "IconPlus",
})
export default class IconPlus extends Vue {}
</script>
