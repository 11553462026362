<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="primary">
                    <ion-button
                        class="button-close"
                        color="danger"
                        @click="handleClose"
                    >
                        <IconTimes class="text-black" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div class="d-flex align-items-center justify-content-center h-100">
                <ion-card>
                    <ion-card-content class="text-center">
                        <img class="mt-3" :src="webPath" />
                    </ion-card-content>
                </ion-card>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./PreviewImageModalDedication.ts"></script>
