import { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { UploadClient, UploadFile } from '@/../client-generator/generated-clients/api-clients';
import config from '@/core/config/config';
import axios from '../utilities/axios-instance-auth';

//override uploadPost method because multipart upload is used
class CustomUploadClient extends UploadClient {
    private instanceCustom: AxiosInstance;
    private baseUrlCustom: string;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        super(baseUrl, instance);
        this.instanceCustom = instance ? instance : axios;
        this.baseUrlCustom = baseUrl !== undefined && baseUrl !== null ? baseUrl : 'http://127.0.0.1:8000/api';
    }

    async uploadPostCustom(base64: string, filename: string, cancelToken?: CancelToken | undefined): Promise<UploadFile> {
        let url_ = this.baseUrlCustom + '/upload/';
        url_ = url_.replace(/[?&]$/, '');

        const blob = await fetch(base64).then((res) => res.blob());

        const formData = new FormData();
        formData.append('file', blob, filename);

        const options_ = {
            data: formData,
            method: 'POST',
            url: url_,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
            cancelToken,
        } as AxiosRequestConfig;

        return this.instanceCustom
            .request(options_)
            .catch((_error: any) => {
                if (_error && _error.isAxiosError && _error.response) {
                    return _error.response;
                } else {
                    throw _error;
                }
            })
            .then((_response: AxiosResponse) => {
                return this.processUploadPost(_response);
            });
    }
}

const uploadClient = new CustomUploadClient(config.baseApiUrl, axios);

export default uploadClient;
