const timeFormattingOptins = {
    hour: 'numeric',
    minute: 'numeric',
};

const dateFormattingOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
};

const dateTimeFormattingOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
};

export { timeFormattingOptins, dateFormattingOptions, dateTimeFormattingOptions };
