import contentsClient from '@/core/api/contents-service';
import { Patient, QuestionCreate } from '@/../client-generator/generated-clients/api-clients';
import { Vue, Options } from 'vue-class-component';
import IconTimes from '@/views/components/icons/IconTimes.vue';
import { IonButtons } from '@ionic/vue';
import { message } from '@/core/utilities/utilities';
import { DECREMENT_PATIENT_REMAINING_QUESTIONS } from '@/core/store/types/action-types';
import { mapState } from 'vuex';

@Options({
    name: 'AskQuestionModal',
    components: {
        IconTimes,
        IonButtons,
    },
    computed: {
        ...mapState(['selectedPatient']),
    },
})
export default class AskQuestionModal extends Vue {
    message = message;
    questionText = '';
    questionError = false;
    selectedPatient!: Patient;

    get remainingChars() {
        return 350 - this.questionText.length;
    }

    async cancel() {
        await (window as any).currentModal.dismiss();
        delete (window as any).currentModal;
    }

    async submit() {
        if (!this.questionText.length) return;
        try {
            if (this.selectedPatient.id === undefined) return;
            const data = new QuestionCreate({ message: this.questionText });
            await contentsClient.question(data, this.selectedPatient.id.toString());
            this.$store.dispatch(DECREMENT_PATIENT_REMAINING_QUESTIONS);
            await this.cancel();
        } catch (error) {
            switch (error.status) {
                case 400:
                    this.questionError = true;
                    break;
                default:
                    this.message(this.$t('apiError'));
                    break;
            }
        }
    }
}
